import { useEffect, useState } from "react";

const DeviceLoader = ({ id, active, radient, img }) => {
  const [state, setState] = useState(0);
  let duration = Math.floor(1000 / radient);
  useEffect(() => {
    const stateInterval = setInterval(() => {
      if (state < radient) {
        setState((state) => state + 1);
      }
      else{
        clearInterval(stateInterval);
      }
    }, duration);
    return () => {
      clearInterval(stateInterval);
    };
  }, [state, radient]);
  useEffect(() => {
    setState(0);
  }, [active]);
  return (
    <label htmlFor={"energy-switch" + id} className="scroll-ball">
      <div
        className={`device rounded-circle `}
        style={{
          background: `radial-gradient(closest-side, ${
            active ? "var(--color5)" : " #555453"
          } 86%, transparent 90%), conic-gradient(${
            active ? "white" : " #555453"
          } ${state}%, ${active ? "var(--color5)" : " #555453"} 0)`,
        }}
      >
        <img
          src={require(`../assets/images/${img}.png`)}
          alt=""
          // className={`${active ? "active-img" : null}`}
        />
      </div>
    </label>
  );
};

export default DeviceLoader;
