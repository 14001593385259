import { useEffect, useState } from "react";

const Counter = ({ value }) => {
  const [state, setState] = useState(0);
  let duration = Math.floor(5000 / value);
  useEffect(() => {
    const stateInterval = setInterval(() => {
      if (state < value) {
        setState((state) => state + 1);
      }
    }, duration);
    return () => {
      clearInterval(stateInterval);
    };
  }, [state]);
  useEffect(() => {
    setState(0);
  }, [value]);
  return state;
};

export default Counter;
