const SwitchKey = ({id,checked,alarmFunc}) => {
  return (
    <div className="switch-key">
      <input
        type="checkbox"
        id={id}
        className="d-none"
        checked={checked}
        onChange={(e) =>alarmFunc(e,id)}
      />
      <label htmlFor={id} className="scroll-ball" />
    </div>
  );
};

export default SwitchKey;
