import { cloneElement, createContext, useContext, useEffect, useRef, useState } from "react";
import useOnClickOutside from "../hooks/useOnClickOutSide";

const DropDownContext=createContext();

export const DropDown = ({children}) => {
    const [open,setOpen]=useState(false);
    const selectContainerRef = useRef(null);
    const clickOutsideHandler = () => setOpen(false);
    useOnClickOutside(selectContainerRef,clickOutsideHandler);
    
    return (
        <div className='position-relative' ref={selectContainerRef}>
          <DropDownContext.Provider value={{open,setOpen}}>
                {children}
          </DropDownContext.Provider>
        </div>
    )
}

function Body({value,setlocation}){
    const {open,setOpen}=useContext(DropDownContext);
    return (
        <ul className={`dropdown-menu border-0 w-100 ${open?"d-block":""}`} >
            {value.map((item,idx)=>{
         return <li key={idx}>
           <button
             type="button"
             className='d-flex align-items-center justify-content-between px-3 w-100'
            //  onClick={() =>{setlocation(idx);setOpen(!open)}}
           >
             <p className="fs-regular-light">{item}</p> 
           </button>
         </li>

            })}
       </ul>
    )
}

function HeaderWithContent({children}){
    const {open,setOpen}=useContext(DropDownContext);
    const toggleHeaderFun=(e)=>{
        setOpen(!open)
    }
    return cloneElement(children,{onClick: toggleHeaderFun });
}

function BodyWithContent({children}){
  const {open}=useContext(DropDownContext);
  return cloneElement(children,{className:`${children.props.className} ${open?"d-block":""}`})

}

DropDown.Body=Body;
DropDown.HeaderWithContent=HeaderWithContent;
DropDown.BodyWithContent=BodyWithContent;