import { Link, NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useMemo, useRef, useState } from "react";
import 'swiper/css';
import { DropDown } from "../components/DropDown";
import Counter from "../components/Counter";
import SwitchKey from "../components/SwitchKey";
import QrReader from 'react-qr-scanner'
import DeviceLoader from "../components/DeviceLoader";

const Home = () => {
  const [alarmId,setAlarmId]=useState(0);
  const featureData =[
    { img: "homeininactive",activeImg:"homeinactive"},
    { img: "homeoutinactive" ,activeImg:"homeoutactive"},
    { img: "suninactive" ,activeImg:"sunactive"},
    { img: "nightinactive" ,activeImg:"nightactive"},
    { img: "grandfatherinactive" ,activeImg:"grandfatheractive"},
    { img: "poweroffinactive" ,activeImg:"poweroffactive"},
  ];
  const [headerFeature,setHeaderFeature]=useState("homeininactive");
  const [alarmData,setAlarmData]=useState([
    {id:"7993",img:"curtains",name:"Curtains",class:"item-border-bottom item-border-right ps-0",active:false},
    {id:"6086",img:"doors",name:"Doors",class:"item-border-bottom item-border-right",active:true},
    {id:"2757",img:"connecteddevices",name:"Connected Devices",class:"item-border-bottom pe-0",active:false},
    {id:"7121",img:"gardenwatering",name:"Garden Watering",class:"item-border-right ps-0",active:false},
    {id:"2563",img:"smartplus",name:"Smart Plugs",class:"item-border-right",active:true},
  ]);
  const footerData=[
    {img:"homemenuinactive",activeImg:"homemenuactive",name:"Home",device:24,path:"/"},
    {img:"bedroominactive",activeImg:"bedroomactive",name:"Bedroom",device:2,path:"/bedroom"},
    {img:"kitcheninactive",activeImg:"kitchenactive",name:"Kitchen",device:4,path:"/kitchen",active:true},
    {img:"livingroominactive",activeImg:"livingroomactive",name:"Living Room",device:8,path:"/livingroom"},
  ]
  const footerData1=[
    {img:"hallwayinactive",activeImg:"hallwayactive",name:"Hallway",device:3,path:"/hallyway"},
    {img:"backyardinactive",activeImg:"backyardactive",name:"Backyard",device:7,path:"/backyard"},
    {img:"garageinactive",activeImg:"garageactive",name:"Garage",device:8,path:"/garage"},
    {img:"moremenu",activeImg:"moremenu",name:"More...",device:"",path:"/more"},
  ];
  const sliderData=[
    {img:"bedroom",name:"Bed Room"},
    {img:"frontdoor",name:"Front Door"},
    {img:"kitchen",name:"kitchen"},
    {img:"bedroom",name:"Bed Room"},
    {img:"frontdoor",name:"Front Door"},
  ];
  const [energyOverView,setEnergyOverView]=useState([
    {id:4088,img:"light",name:"Lights",value:10,radient:56,transition:"0",active:true},
    {id:9170,img:"oven",name:"oven",value:2,radient:32,transition:"45",active:false},
    {id:4670,img:"ac",name:"AC",value:5,radient:90,transition:"90",active:true},
    {id:2859,img:"refrigerator",name:"Refrigerator",value:8,radient:39,transition:"130",active:true},
    {id:5587,img:"tv",name:"TV",value:4,radient:81,transition:"182",active:false},
  ]);
  const swiperRef=useRef(null);
  const alarmInput=useRef(null);
  const timeLeftArray = [
    "15 Mins left on baking",
    "25 Mins left on workout",
    "10 Mins left on download",
    "30 Mins left on commute",
  ];
  const selctedArrow =useMemo(()=>{
   return timeLeftArray.at(alarmId)
  },[alarmId]);

  const alarmDataFunc=(value)=>{
    setAlarmData(prev=>{
      return prev.map(item=>{
        if(value.includes(item.img)){
          return {...item,active:true}
        }
        return {...item,active:false}
      })
    })
  }

  useEffect(()=>{
     if(headerFeature==="homeininactive"){
      const value=["doors","smartplus"]
      alarmDataFunc(value)
     }
     if(headerFeature==="homeoutinactive"){
      const value=["curtains","smartplus"]
      alarmDataFunc(value)
     }
     if(headerFeature==="suninactive"){
      const value=["curtains","gardenwatering"]
      alarmDataFunc(value)
     }
     if(headerFeature==="nightinactive"){
      const value=["connecteddevices","smartplus"]
      alarmDataFunc(value)
     }
     if(headerFeature==="grandfatherinactive"){
      const value=["curtains","smartplus"]
      alarmDataFunc(value)
     }
     if(headerFeature==="poweroffinactive"){
      const value=[]
      alarmDataFunc(value)
     }
  },[headerFeature])

  const alarmFunc=(e,id)=>{
    setAlarmData((prev) => {
      return prev.map((childObject) => {
        if (childObject.id === id) {
            return { ...childObject, active: e.target.checked };
        }
        return { ...childObject };
      });
    })
  }

  const energyDeviceFunc=(e,id)=>{
    setEnergyOverView(
       energyOverView.map((childObject) => {
        if (childObject.id === id) {
            return { ...childObject, active: e.target.checked };
        }
        return { ...childObject };
      })
    )
  }

  const [stopRecord,setStopReader]=useState(false);
  function handleScan(data){
    if(data &&alarmData.length<6){
      setAlarmData([...alarmData,{id:"2567",img:"smartplus",name:"Smart Plugs",class:"",active:true,removeBtn:true}])
      setStopReader(true);
    }
  }
  function handleError(err){
    console.error(err)
  }
  function removeNewDevice(){
    const copyArr = [...alarmData];
copyArr.splice(-1);
    setAlarmData(copyArr);
    setStopReader(false);
  }

  return (
    <section className="home-section d-flex flex-column">
      <header className="d-flex align-items-start justify-content-between">
        <Link to="/" className="split-con-left">
          <img
            src={require("../assets/images/logo.png")}
            alt=""
            className="logo-img"
          />
          <h2 className="fs-topic">Smart Home</h2>
        </Link>
        <div className="split-con-center features-con-main d-flex align-items-center flex-column">
          <div className="features-con d-flex align-items-center">
            {featureData.map((item, idx) => {
              return (
                <button type="button"
                onClick={()=>setHeaderFeature(item.img)}
                 key={idx} className={`rounded-circle ${item.img===headerFeature?"active":""}`}>
                  <img
                    src={require(`../assets/images/${item.img===headerFeature?item.activeImg:item.img}.png`)}
                    alt=""
                  />
                </button>
              );
            })}
          </div>
          <h3 className="fs-topic-bold">Welcome Home</h3>
          <div className="d-flex flex-column align-item-center">
            <DropDown>
              <DropDown.HeaderWithContent>
                <button
                  type="button"
                  className="user-name-drop d-flex align-items-center gap-2"
                >
                  <p className="fs-small-grey ">Art Nicholas!</p>
                  <img src={require("../assets/images/droplogo.png")} alt="" />
                </button>
              </DropDown.HeaderWithContent>
              <DropDown.Body value={["Danel","Androws"]}></DropDown.Body>
            </DropDown>
          <p className="fs-regular-light text-center">6 Occupants</p>
          </div>
        </div>
        <div className="split-con-right d-flex justify-content-end">
        <div className="weather-con mw-100">
        <DropDown>
            <DropDown.HeaderWithContent>
            <button type="button" className="d-flex align-items-center gap-2">
            <img
              src={require("../assets/images/droplogo.png")}
              alt=""
              className="drop-down-icon"
            />
            <h2 className="fs-topic-secondary">10:45 AM, Fri Mar 08</h2>
            </button>
          </DropDown.HeaderWithContent>
              <DropDown.Body value={["10:45 AM, Fri Mar 08","10:45 AM, Fri Mar 08"]}></DropDown.Body>
          </DropDown>
          
          <div className="d-flex align-items-center justify-content-between w-100 gap-2">
            <div className="d-flex align-items-center gap-2 weather-sec">
              <img src={require("../assets/images/cloudy.png")} className="cloudy-img" alt=""/>
              <div>
                <p className="fs-topic-secon-light">28&deg;C</p>
                <p className="fs-small-grey">Weather</p>
              </div>
            </div>
            <div>
              <p className="fs-topic-secon-light">77%</p>
              <p className="fs-small-grey">Humidity</p>
            </div>
          </div>
        </div>
        </div>
      </header>
      <div className="main-home-con d-flex align-items-start flex-grow-2">
        <div className="overall-energy-con">
           <div className="device-con rounded-circle w-100 h-100">
            <svg viewBox="0 0 22.968 408.295">
              <path id="Path_10479" data-name="Path 10479"
               d="M5851,5164s39.753,1.316,73.924,14.932,62.759,39.53,62.759,39.53,36.438,37.237,52.771,78.3,12.554,85.942,12.554,85.942a296.873,296.873,0,0,1-10.567,49.824c-3.961,12.9-10.135,26.078-16.923,38.626-19.847,36.688-51.9,58.3-51.9,58.3s-29.277,21.6-59.9,32.057-62.6,9.769-62.6,9.769" transform="translate(-5850.984 -5163.501)" fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="8" opacity="0.18"/>
            </svg>
            {
                energyOverView.map((item,idx)=>{
              return <div className={`child-con energy-child-con d-flex align-items-center flex-column child-con-${idx}`} key={idx}>
                 <input
                   type="checkbox"
                   id={"energy-switch"+item.id}
                   className="d-none"
                   defaultChecked={item.active}
                   onClick={(e) =>energyDeviceFunc(e,item.id)}
                 />
                 {/* <label htmlFor={"energy-switch"+item.id} className="scroll-ball">
                           <div className={`device rounded-circle ` }
                           style={{background:`radial-gradient(closest-side, ${item.active?"var(--color7)":" #555453"} 86%, transparent 90%), conic-gradient(${item.active?"var(--color6)":" #555453"} ${item.radient}%, ${item.active?"var(--color7)":" #555453"} 0)`}}>
                               <img src={require(`../assets/images/${item.img}.png`)} alt="" />
                           </div>
                 </label> */}
                 <DeviceLoader active={item.active} img={item.img} radient={item.radient} id={item.id}></DeviceLoader>
                <p
                 className="fs-regular-light">{item.name} <sub className="fs-small-light">({item.value})</sub></p>
                </div>
                })
            }
           <div className="over-energy d-flex align-items-center flex-column justify-content-center rounded-circle">
            <img src={require("../assets/images/energy.png")} alt="" />
            <h2 className=""><Counter value={154}></Counter></h2>
            <p>Kw</p>
           </div>
           <div className="energy-control-con rounded-5 d-flex align-items-center flex-column">
            <button type="button"><img src={require("../assets/images/notification.png")} alt="" /></button>
            <button type="button"><img src={require("../assets/images/settings.png")} alt="" /></button>
            <button type="button"><img src={require("../assets/images/logout.png")} alt="" /></button>
           </div>
           </div>
         </div>
        <div className="living-room d-flex align-items-center flex-column split-con-center">
          {
            headerFeature!=="grandfatherinactive"?
            <img className="w-50 live-video" src={require("../assets/images/video.png")} alt="" />
            :
            <div className="render-live w-50 live-video rounded-circle overflow-hidden d-flex align-items-center justify-content-center"
            id="render-live">
              {
                !stopRecord?
                <QrReader
                 delay={100}
                 style={{width:"150%",height:"100%"}}
                 onError={handleError}
                 onScan={handleScan}
                 />:
                 <>
                 <img className="w-100" src={require("../assets/images/deviceAdd.png")} alt="" />
                 <h2 className="fs-topic position-absolute">Device Added</h2>
                 </>
              }
            </div>
          }
            <h2 className="room-topic">Living Room</h2>
            <p className="fs-small-light">Motion Detected</p>
            <div className="doors-slider-con w-100 d-flex align-items-center gap-4 poition-relative">
              <button type="button" className="arrow left-arrow rounded-circle"
              onClick={()=>swiperRef.current?.slidePrev()}
               ><img src={require("../assets/images/droplogo.png")} alt="" /></button>
              <div className="man-slider-con w-75">
              <Swiper
                 loop={true}
                spaceBetween={20}
                slidesPerView={3}
                onBeforeInit={(swiper)=>swiperRef.current=swiper}
              >
                {
                  sliderData.map((item,idx)=>{
               return <SwiperSlide key={idx}>
                <div className="item item-1 d-flex align-items-center justify-content-center flex-column w-100">
                            <img className="w-100" src={require(`../assets/images/${item.img}.png`)} alt="" />
                            <p className="fs-small-grey">{item.name}</p>
                          </div>
                </SwiperSlide>
                  })
                }
              </Swiper>
              </div>
              <button type="button" className="arrow right-arrow rounded-circle"
              onClick={()=>swiperRef.current?.slideNext()}><img src={require("../assets/images/droplogo.png")} alt="" /></button>
            </div>
        </div>
        <div className="alarm-section split-con-right">
        <h2 className="fs-topic">Alarms(6)</h2>
        <div className="input-section d-flex rounded-4">
        <input type="text" className="w-100 fs-small-grey d-none" readOnly value={selctedArrow}/>
        <>
      <Swiper
        direction={'vertical'}
        allowTouchMove= {false}
        className="mySwiper w-100 h-100"
        onBeforeInit={(swiper)=>alarmInput.current=swiper}
      >
        {
          timeLeftArray.map((item,idx)=>{
            return<SwiperSlide key={idx}><p className="fs-small-grey h-100 d-flex align-items-center">{item}</p></SwiperSlide>
          })
        }
      </Swiper>
    </>
        <div className="d-flex justify-content-center flex-column">
          <button type="button" className="top-arrow arrow" onClick={()=>{setAlarmId(prev=>prev?prev-=1:0);alarmInput.current?.slidePrev()}}><img  src={require("../assets/images/droplogo.png")} alt="droplogo" /></button>
          <button type="button" className="bottom-arrow arrow" onClick={()=>{setAlarmId(prev=>{
            if(timeLeftArray.length-1>prev){ return  prev+=1 }
             else {return prev}
          });alarmInput.current?.slideNext()}}><img  src={require("../assets/images/droplogo.png")} alt="" /></button>
        </div>
        </div>
        <div className="device-con d-flex align-items-center flex-wrap m-2">
          {
           alarmData.map((item,idx)=>{
              return(
           <div key={idx} className={`item ${item.class} position-relative `}>
            <div className="item-sub-con d-flex flex-column justify-content-between">
             <div className="w-100 d-flex gap-4 align-items-center justify-content-between">
              <img className="device-icon" src={require(`../assets/images/${item.img}.png`)} alt="curtains" />
              <SwitchKey alarmFunc={alarmFunc} id={item.id} checked={item.active}></SwitchKey>
             </div>
             <div className="d-flex align-items-center gap-2">
             <p className="fs-regular-light ">{item.name}</p>
             {item.removeBtn?
             <button type="button" className="remove-btn" onClick={removeNewDevice}>
              <img src={require("../assets/images/close.png")} alt="" /></button>:null}
             </div>
            </div>
            
           </div>
              )
            })
          }
        </div>
        </div>
      </div>
      <footer className="d-flex align-items-center justify-content-between flex-grow-1">
        {
          footerData.map((item,idx)=>{
              return <NavLink to={item.path} key={idx} 
              className={({ isActive}) =>[ isActive ? "active" : "","item d-flex align-items-center flex-column",item.active?"notify":""].join(" ")}
              >
                    {({ isActive}) =>  <>
                    <div className="icon-con rounded-2">
                     <img src={require(`../assets/images/${isActive?item.activeImg:item.img}.png`)} alt=""/>
                   </div>
                    <p className="fs-regular-light">{item.name}</p>
                    <p className="fs-small-grey">{item.device} Devices</p>
                    </>
                    }
                  </NavLink>
          })
        }
        <button type="button" className="mic-con rounded-circle"><img src={require("../assets/images/mic.png")} alt="" /></button>
        {
          footerData1.map((item,idx)=>{
              return <NavLink to={item.path}  key={idx} 
              className={({ isActive}) =>[ isActive ? "active" : "","item d-flex align-items-center flex-column"].join(" ")}
              >
                {({ isActive}) =>  <>
                    <div className={`icon-con ${item.img==="moremenu"?"moremenu":""} rounded-2`}>
                      <img src={require(`../assets/images/${isActive?item.activeImg:item.img}.png`)} alt=""/></div>
                    <p className="fs-regular-light">{item.name}</p>
                    <p className="fs-small-grey">{item.device} Devices</p>
                    </>}
                  </NavLink>
          })
        }
      </footer>
    </section>
  );
};

export default Home;
